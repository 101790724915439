import React, { useEffect } from "react";
import { contentHeader, TableHeader } from "../../Constancs/cssconst";
import { getList, GetRecords, URLPRIFIX } from "../../functions/api";
import ListView from "./ListView";
import { getlocal } from "../../functions/localstorage";

const DocumentUpdate = ({ store, props }) => {
  useEffect(() => {
    const user = getlocal("system")?.user || {};
    console.log("System Data -", user);

    getList({
      listUrl: "/subtask/getsubtasklist",
      field: user?.role == "1" ? "status" : "assigned_to",
      search: user?.role == "1" ? "" : user?.id,
      sort: "asc",
      page: 1,
      count: 100,
    }).then((res) => {
      let list = res?.data?.list;
      list = list.map((item) => {
        let duedate = new Date(item?.dueDate || "");
        let currentdate = new Date();
        let diffirence = duedate - currentdate;
        let diffDays = Math.floor(diffirence / (1000 * 60 * 60 * 24)) + 1;
        if (Math.sign(diffDays) === -1) {
          item.delay_days = diffDays;
          item.error = true;
        } else {
          item.delay_days = "0";
        }
      });

      store.setvalue("DocumentList", res?.data?.list);
    });
  }, []);
  return (
    <>
      <h1 className={contentHeader}>Document Updates</h1>
      <ListView
        Tablehead={"bg-Alice_Blue min-w-[150px] pr-[10px]  " + TableHeader}
        Tablerecord=" max-sm:text-[26px] md:text-[20px] lg:text-[14px] xl:text-[14px] cursor-pointer "
        width={"100%"}
        height={"100%"}
        onclick={(index) => {
          store.navTo("/" + URLPRIFIX + "/tasks/view/" + index);
        }}
        header={[
          ["Client Name", "clientName"],
          ["Project Name", "projectName"],
          ["Document Name", "task_title"],
          ["Task Name", "subtask_title"],
          ["Priority", "priority"],
          ["Status", "status"],
          ["Assigned To", "assigned_to_username"],
          ["Due Date", "dueDate"],
          ["Delay days", "delay_days"],
          //   {
          //     "id": 9,
          //     "form_id": 13,
          //     "project_id": 1,
          //     "task_id": 16,
          //     "attachmenturl": null,
          //     "assigned_to": 1,
          //     "subtask_title": "test",
          //     "description": null,
          //     "priority": "High",
          //     "status": "Draft",
          //     "dueDate": "2024-10-01T16:45:00Z",
          //     "createdDate": "2024-09-30T11:15:35.823596Z",
          //     "createdBy": 1,
          //     "modifiedDate": "2024-09-30T11:15:35.823658Z",
          //     "modifiedBy": 1,
          //     "activeStatus": true,
          //     "deleteStatus": false,
          //     "contact_id": 12,
          //     "complete_note": null,
          //     "hold_note": null,
          //     "clientName": "Walmart",
          //     "assigned_to_username": "admin"
          // }
        ]}
        records={store.getvalue("DocumentList")}
      />
    </>
  );
};

export default DocumentUpdate;
