// src/firebase.js
// import { initializeApp } from '/firebaseapp';
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
// import { getMessaging } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: "AIzaSyCyGkk27-80KMcQKBbz4VClZcY-FuW-t2k",
  authDomain: "absolute-test-fd85a.firebaseapp.com",
  projectId: "absolute-test-fd85a",
  storageBucket: "absolute-test-fd85a.appspot.com",
  messagingSenderId: "949699008411",
  appId: "1:949699008411:web:fafa9f57c9bb6ad4bc333e",
  measurementId: "G-SWXBWRDV10",
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

// Initialize Firebase Cloud Messaging and get a reference to the service
const messaging = getMessaging(firebaseApp);

export const requestPermission = () => {
  try {
    return getToken(messaging, {
      vapidKey:
        "BBDrB69kvectpZhtZ_4h0L_gqvcmNqTBVJp399HygCgz9xP83WHpt6SbnrLw3n7AQzW4jH-kNTD1uoKUe6I2L7Q", // Your VAPID Key from Firebase Console
    }).then((token) => {
      if (token) {
        return { status: true, token: token };
      } else {
        return { status: false, error: "No token generated" };
      }
    });
  } catch (error) {
    console.error("An error occurred while retrieving token:", error);
    return { status: false, errormessage: error };
  }
};

export { messaging };
