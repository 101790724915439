import React, { useEffect, useRef, useState } from "react";
import ReactQuill, { Quill } from "react-quill";
import imageResize from "quill-image-resize-module-react";
import "react-quill/dist/quill.snow.css";
import "./wordEditor.css";
import htmlEditButton from "quill-html-edit-button";
import { useStore } from "../../StateProvider";
import { saveRecord, UpdateRecord } from "../../../functions/api";
import mammoth from "mammoth";
import PizZip from "pizzip";
import Docxtemplater from "docxtemplater";
import { saveAs } from "file-saver";
import { PDFDocument } from "pdf-lib";
import { Document, Packer, Paragraph, TextRun, AlignmentType } from "docx";
import { PrimaryBTN, secondaryBTN } from "../../../Constancs/cssconst";

const QuillEditor = ({comments,quillRef}) => {
  const store = useStore();

  const [editorContent, setEditorContent] = useState("");
 

  const handleImportDocx = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = async (e) => {
      const arrayBuffer = e.target.result;

      // First, extract the main content using Mammoth
      const mammothResult = await mammoth.convertToHtml({ arrayBuffer });
      let content = mammothResult.value;

      // Next, parse the DOCX file for comments and tracked changes
      const zip = new PizZip(arrayBuffer);
      const doc = new Docxtemplater(zip, {
        paragraphLoop: true,
        linebreaks: true,
      });

      const textWithCommentsAndTracking = doc.getFullText();
      alert(textWithCommentsAndTracking);

      // Placeholder: Parse comments and changes from `textWithCommentsAndTracking`
      // You will need to implement a parsing logic that extracts comments and traces

      setEditorContent(content);
      // Optionally, set parsed comments
      // setComments(parsedComments);
    };
    reader.readAsArrayBuffer(file);
  };

  const handleExportDocx = async () => {
    try { 
      const htmlContent = editorContent;

      // Create a new Document
      const doc = new Document({
        sections: [
          {
            properties: {},
            children: [
              new Paragraph({
                children: htmlToTextRun(store.getvalue("editor")),
                alignment: AlignmentType.LEFT, // Adjust alignment if needed
              }),
            ],
          },
        ],
      });

      // Convert Document to buffer and save as DOCX
      const blob = await Packer.toBlob(doc);
      saveAs(blob, "document.docx");
    } catch (error) {
      console.error("Error exporting DOCX file:", error);
    }
  };

  // Function to convert HTML to docx TextRun objects
  const htmlToTextRun = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    const textRuns = [];

    const processNode = (node) => {
      if (node.nodeType === Node.TEXT_NODE) {
        if (node.textContent.trim()) {
          textRuns.push(new TextRun(node.textContent));
        }
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        let textRun = new TextRun({
          text: node.textContent,
          bold: node.nodeName === "STRONG" || node.style.fontWeight === "bold",
          italics: node.nodeName === "EM" || node.style.fontStyle === "italic",
          underline: node.style.textDecoration === "underline",
          font: node.style.fontFamily || "Arial",
          size: parseInt(node.style.fontSize) || 24,
          color: node.style.color || "000000",
        });
        textRuns.push(textRun);

        Array.from(node.childNodes).forEach((child) => processNode(child));
      }
    };

    Array.from(doc.body.childNodes).forEach((node) => processNode(node));

    return textRuns;
  };

  const handleExportDocx2 = async () => {
    try {
      // Clean the editor content by removing HTML tags
      const plainText = editorContent.replace(/<\/?[^>]+(>|$)/g, "");

      // Create a new Document
      const doc = new Document({
        sections: [
          {
            properties: {},
            children: [
              new Paragraph({
                children: [new TextRun(plainText)],
              }),
            ],
          },
        ],
      });

      // Convert Document to buffer and save as DOCX
      const blob = await Packer.toBlob(doc);
      saveAs(blob, "document.docx");
    } catch (error) {
      console.error("Error exporting DOCX file:", error);
    }
  };

  const handleExportPDF = async () => {
    try {
      const doc = await PDFDocument.create();
      const page = doc.addPage();

      // Example: Simple text rendering
      page.drawText(store.getvalue("editor").replace(/<\/?[^>]+(>|$)/g, ""), {
        x: 50,
        y: 750,
        size: 12, // Font size
      });

      const pdfBytes = await doc.save();
      saveAs(new Blob([pdfBytes]), "document.pdf");
    } catch (error) {
      console.error("Error exporting PDF file:", error);
    }
  };

  const handleExportPDF2 = async () => {
    const doc = await PDFDocument.create();
    const page = doc.addPage();
    page.drawText(editorContent.replace(/<\/?[^>]+(>|$)/g, ""));
    const pdfBytes = await doc.save();
    saveAs(new Blob([pdfBytes]), "document.pdf");
  };

 

  return (
    <div className="text-primary h-screen w-screen overflow-y-auto">
      <input type="file" onChange={handleImportDocx} />

      <ReactQuill
      className="w-[80%] m-auto"
        ref={quillRef}
        value={store.getvalue("editor")}
        onChange={(e) => [store.setvalue("editor", e)]}
        modules={QuillEditor.modules}
        formats={QuillEditor.formats}
      />
      {/* <div className="flex justify-end gap-5 px-10">
        <button className={PrimaryBTN} onClick={handleExportDocx}>
          Export as DOCX
        </button>
        <button className={PrimaryBTN} onClick={handleExportPDF}>
          Export as PDF
        </button>
        <button className={secondaryBTN} onClick={handleAddComment}>
          Add Comment
        </button>
      </div> */}
      <div>
        <h3>Comments:</h3>
        <ul>
          {comments.map((comment) => (
            <li key={comment.id}>
              {comment.text} (at position {comment.range.index})
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

QuillEditor.modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ size: ["small", false, "large", "huge"] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { align: "" },
      { align: "center" },
      { align: "right" },
      { align: "justify" },
    ],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image", "video", "formula"],
    [{ color: [] }, { background: [] }],
    ["clean"],
    ["code-block"],
  ],
};

QuillEditor.formats = [
  "header",
  "font",
  "bold",
  "italic",
  "underline",
  "strike",
  "list",
  "bullet",
  "color",
  "background",
  "indent",
  "align",
  "link",
  "image",
];

// Pseudocode for parsing comments and changes
function parseDocxForCommentsAndChanges(doc) {
  const comments = [];
  const changes = [];

  // Example: Iterate through document XML and extract comments
  doc.comments.forEach((comment) => {
    comments.push({
      author: comment.author,
      text: comment.text,
      position: comment.position,
    });
  });

  // Example: Iterate through document XML and extract tracked changes
  doc.body.forEach((element) => {
    if (element.type === "insertion") {
      changes.push({
        type: "insertion",
        text: element.text,
        position: element.position,
      });
    } else if (element.type === "deletion") {
      changes.push({
        type: "deletion",
        text: element.text,
        position: element.position,
      });
    }
  });

  return { comments, changes };
}

export default QuillEditor;
