import React, { useEffect, useState } from "react";
import Sider from "./Sider";
import PageRouter from "./PageRouter";
import Header from "./Header";
import { useStore } from "../StateProvider";
import { getList, URLPRIFIX } from "../../functions/api";
import { getlocal } from "../../functions/localstorage";
import { ToastContainer } from "react-toastify";
import LoadingSpinner from "../Elements/LoadingSpinner";

const Layout = ({ children }) => {
  const store = useStore();
  const user = getlocal("system")?.user || {};
  const [sider, setsider] = useState("max-sm:hidden");
  const props = getlocal("properties");
  // console.log("props", props);

  let category = {};

  let menus = {
    Dashboard: {
      "meta-data": { parent: true },
      link: "/" + URLPRIFIX + "/dashboard",
    },
    "Admin Console": {
      disabled: user?.role != "1",
      "meta-data": { icon: "/Assets/images/mastericon.png", parent: true },
      child: {
        Users: {
          "meta-data": { parent: false },
          link: "/" + URLPRIFIX + "/users/list",
          disabled: !props?.users?.view,
        },
        Category: {
          "meta-data": { icon: "", parent: false },
          link: "/" + URLPRIFIX + "/categories/list",
          disabled: !props?.categories?.view,
        },
        Film: {
          "meta-data": { icon: "", parent: false },
          link: "demo/filmmaster/list",
          disabled: !props?.film?.view,
        },
        Contacts: {
          "meta-data": { icon: "", parent: false },
          link: "/" + URLPRIFIX + "/contacts/list",
          disabled: !props?.contacts?.view,
        },

        // CreateLink: {
        //   "meta-data": { icon: "", parent: false },
        //   link: "/"+URLPRIFIX+"/contact/list"
        // },
      },
    },
    CMS: {
      "meta-data": { icon: "/Assets/images/cms.png", parent: true },
      child: {
        "Non-Litigation": {
          "meta-data": { icon: "", parent: false },
          child: category,
          disabled: !props?.nonlitigation?.view,
        },
        "Client Form": {
          "meta-data": { icon: "", parent: false },
          child: {
            "All Forms": {
              "meta-data": { icon: "", parent: false },
              link: "/" + URLPRIFIX + "/forms/list",
              disabled: !props?.forms?.view,
            },
            "Client Submitted": {
              "meta-data": { icon: "", parent: false },
              link:
                "/" +
                URLPRIFIX +
                "/forms/list?field=status&search=Submitted By Client",
              disabled: !props?.forms?.view,
            },
          },
        },
        Projects: {
          "meta-data": { icon: "", parent: false },
          link: "/" + URLPRIFIX + "/projects/list",
          disabled: !props?.projects?.view,
        },
        Document: {
          "meta-data": { icon: "", parent: false },
          link: "/" + URLPRIFIX + "/documents/list",
          disabled: !props?.documents?.view,
        },
        Task: {
          "meta-data": { icon: "", parent: false },
          child: {
            "All Task": {
              "meta-data": { icon: "", parent: false },
              link: "/" + URLPRIFIX + "/tasks/list",
              disabled: user?.role != "1",
            },
            "Assigned_to Me": {
              "meta-data": { icon: "", parent: false },
              link:
                "/" +
                URLPRIFIX +
                "/tasks/list?field=assigned_to_username&search=" +
                user?.name,
              disabled: !props?.tasks?.view,
            },
            "Pending Approval": {
              "meta-data": { icon: "", parent: false },
              link:
                "/" +
                URLPRIFIX +
                "/tasks/list?field=status&search=waitingforapproval",
              disabled: user?.role != "1",
            },
          },
        },
      },
    },
    Profile: {
      "meta-data": { icon: "/Assets/images/account.png", parent: true },
      child: {
        "My Profile": {
          "meta-data": { icon: "", parent: false },
          link: "/" + URLPRIFIX + "/users/profile/" + (user?.id || "0"),
        },
        // "Profile Setting": {
        //   "meta-data": { icon: "", parent: false },
        //   link: "/"+URLPRIFIX+"/profile",
        // },
        Logout: {
          "meta-data": { icon: "", parent: false },
          button: "logout",
        },
      },
    },
  };

  useEffect(() => {
    getList({
      listUrl: "/clientcategory/getClientCategorylist",
      field: "",
      sort: "asc",
      page: 1,
      count: 100,
    }).then((res) => {
      console.log("Responces of category");
      if (res.success) {
        res?.data?.list?.map((item) => {
          category[item.categoryName] = {
            "meta-data": { icon: "", parent: false },
            link: "/" + URLPRIFIX + "/nonlitigation/" + item.categoryName,
          };
        });
      }
    });
    // Hotel: {
    //   "meta-data": { icon: "", parent: false },
    //   link: "/demo/nonlitigation/hotel",
    // },
  }, []);

  // the menu has some indicators to classify
  // every object has major components like
  //   1. "meta-data" - has the basic infor like icon location and this object is parent or not as Boolean
  //   2. child - which has the info like what are the child the going to have
  //   3. link - which create the object as a link button so we can easy to navigate to the link
  //   4. button - which help to perform any function which the store has

  return (
    <>
      <main className=" h-screen bg-secondary text-primary font-Poppins grid grid-rows-12 relative">
        <div className=" row-span-2 max-sm:row-span-1">
          <Header store={store} setsider={setsider} />
        </div>
        <div className="grid grid-cols-10 row-span-10 max-sm:row-span-11">
          <div
            className={
              "col-span-2 h-full bg-primary rounded-tr-[50px] Scrollleft " +
              sider
            }
          >
            <div className="innerscroll bg-primary text-secondary h-full max-sm:rounded-none max-sm:absolute max-sm:right-0 max-sm:w-full max-sm:z-50">
              <Sider menus={menus} setsider={setsider} />
            </div>
          </div>
          <div className="col-span-8 max-sm:col-span-10 overflow-x-auto px-[2%] relative">
            {store.getsystemvalue("loading") && <LoadingSpinner />}
            {children}{" "}
          </div>
        </div>
      </main>
    </>
  );
};

export default Layout;
