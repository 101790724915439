import React from "react";
import { useStore } from "../StateProvider";
import html2pdf from "html2pdf.js";

const HtmlToPdf = ({ filename = "Document", HtmlContent = "" }) => {
  const store = useStore();

  const generatePDF = () => {
    let data = store.getrecord();
    console.log("Data:", data.film);
    console.log("Data:", data.name_of_Artist);

    const options = {
      margin: 1,
      filename: `${filename}.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };

    const pdfContent = document.createElement("div");

    const createAnchor = (url) => {
      if (!url) return "No Attachment";
      const fileName = url.substring(url.lastIndexOf("/") + 1);
      const absoluteUrl = `https://absolute-backend.binarywavesolutions.com/files/preview?key=${encodeURIComponent(
        url
      )}`;
      return `<a href='${absoluteUrl}' target="_blank"; style="color: blue;">${fileName}</a>`;
    };

    pdfContent.innerHTML = ` <div style="margin-bottom: 20px; color:#000042;">${HtmlContent}</div>`;

    document.body.appendChild(pdfContent);

    html2pdf()
      .from(pdfContent)
      .set(options)
      .save()
      .then(() => {
        document.body.removeChild(pdfContent);
      });
  };
  return (
    <button
      className="py-3 px-10 rounded-xl font-bold bg-tertiary text-primary"
      onClick={() => {
        generatePDF();
      }}
    >
      Download Pdf
    </button>
  );
};

export default HtmlToPdf;
