import Dropdown from "../../Components/Fields/Dropdown";
import { GetRecords, saveRecord, UpdateRecord } from "../../functions/api";
import React, { useEffect } from "react";
import html2pdf from "html2pdf.js";
import { useParams } from "react-router-dom";
import String from "../../Components/Fields/String";
import { useStore } from "../../Components/StateProvider";
import FormView from "../../Components/Elements/FormView";
import Attachement from "../../Components/Fields/Attachement";
import { getlocal } from "../../functions/localstorage";
import { TiThList } from "react-icons/ti";

const FormsForm = () => {
  const { option, id } = useParams();
  // const setrecord = "/users/register";
  const tablename = "form";
  const getrecord = `/forms/getforms/${id}`;

  const store = useStore();
  const init = {
    category_of_Service: "",
    email: "",
    father_Name: "",
    film: "",
    film_Id: "",
    name_of_Artist: "",
    nature_of_Service: "",
    phone_no: "",
    assistants_Payments: "",
    no_of_Months: "",
    no_of_Shooting_Days: "",
    payment_Deal: "",
    payment_Trance: "",
    account_holder_Name: "",
    account_no: "",
    bank_Name: "",
    branch: "",
    ifsC_Code: "",
    aadhar_Doc_URL: "",
    aadhar_Number: "",
    gsT_Certificate_URL: "",
    gst: "",
    paN_Doc_URL: "",
    paN_Number: "",
    attachmenturl: "",
  };

  useEffect(() => {
    // console.log("Params",param);

    store.setmodule(tablename, init);
    if (id != undefined) {
      store.setheader(option.toUpperCase() + " " + tablename);
      store.setprops("category_of_Service", { hidden: false, readonly: true });
      store.setprops("email", { hidden: false, readonly: true });
      store.setprops("father_Name", { hidden: false, readonly: true });
      store.setprops("film", { hidden: false, readonly: true });
      store.setprops("film_Id", { hidden: false, readonly: true });
      store.setprops("name_of_Artist", { hidden: false, readonly: true });
      store.setprops("nature_of_Service", { hidden: false, readonly: true });
      store.setprops("phone_no", { hidden: false, readonly: true });
      store.setprops("assistants_Payments", { hidden: false, readonly: true });
      store.setprops("no_of_Months", { hidden: false, readonly: true });
      store.setprops("no_of_Shooting_Days", { hidden: false, readonly: true });
      store.setprops("payment_Deal", { hidden: false, readonly: true });
      store.setprops("payment_Trance", { hidden: false, readonly: true });
      store.setprops("account_holder_Name", { hidden: false, readonly: true });
      store.setprops("account_no", { hidden: false, readonly: true });
      store.setprops("bank_Name", { hidden: false, readonly: true });
      store.setprops("branch", { hidden: false, readonly: true });
      store.setprops("ifsC_Code", { hidden: false, readonly: true });
      store.setprops("aadhar_Doc_URL", { hidden: false, readonly: true });
      store.setprops("aadhar_Number", { hidden: false, readonly: true });
      store.setprops("gsT_Certificate_URL", { hidden: false, readonly: true });
      store.setprops("gst", { hidden: false, readonly: true });
      store.setprops("paN_Doc_URL", { hidden: false, readonly: true });
      store.setprops("paN_Number", { hidden: false, readonly: true });
      store.setprops("attachmenturl", { hidden: false, readonly: true });
      GetRecords(getrecord).then((res) => {
        console.log(tablename + " Data - ", res, res.record);
        if (res.success) {
          init.contact_id = res.record[0].form.contact_id;
          init.category_of_Service =
            res.record[0].industrialName.category_of_Service;
          init.email = res.record[0].industrialName.email;
          init.father_Name = res.record[0].industrialName.father_Name;
          init.film = res.record[0].industrialName.film;
          init.film_id = res.record[0].form.film_id;
          init.name_of_Artist = res.record[0].industrialName.name_of_Artist;
          init.nature_of_Service =
            res.record[0].industrialName.nature_of_Service;
          init.phone_no = res.record[0].industrialName.phone_no;
          init.assistants_Payments = res.record[0].payments.assistants_Payments;
          init.no_of_Months = res.record[0].payments.no_of_Months;
          init.no_of_Shooting_Days = res.record[0].payments.no_of_Shooting_Days;
          init.payment_Deal = res.record[0].payments.payment_Deal;
          init.payment_Trance = res.record[0].payments.payment_Trance;
          init.account_holder_Name =
            res.record[0].bankdetails.account_holder_Name;
          init.account_no = res.record[0].bankdetails.account_no;
          init.bank_Name = res.record[0].bankdetails.bank_Name;
          init.branch = res.record[0].bankdetails.branch;
          init.ifsC_Code = res.record[0].bankdetails.ifsC_Code;
          init.aadhar_Doc_URL = res.record[0].idproofs.aadhar_Doc_URL;
          init.aadhar_Number = res.record[0].idproofs.aadhar_Number;
          init.gsT_Certificate_URL = res.record[0].idproofs.gsT_Certificate_URL;
          init.gst =
            res.record[0].idproofs.gst == "true"
              ? "Registered"
              : "Un-Registered";
          init.paN_Doc_URL = res.record[0].idproofs.paN_Doc_URL;
          init.paN_Number = res.record[0].idproofs.paN_Number;
          init.attachmenturl = res.record[0].idproofs.attachmenturl;
          store.reset(init);
        } else if (res.code === 401) {
          store.logout();
          store.navTo("/login");
        }
      });
    } else {
      store.setheader("CREATE NEW " + tablename);
      store.setprops("activestatus", { hidden: true });
    }
  }, []);

  const generatePDF = () => {
    let data = store.getrecord();
    console.log("Data:", data.film);
    console.log("Data:", data.name_of_Artist);

    const options = {
      margin: 1,
      filename: `ClientForm_${data.film}_${data.name_of_Artist}.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };

    const pdfContent = document.createElement("div");

    const createAnchor = (url) => {
      if (!url) return "No Attachment";
      const fileName = url.substring(url.lastIndexOf("/") + 1);
      const absoluteUrl = `https://absolute-backend.binarywavesolutions.com/files/preview?key=${encodeURIComponent(
        url
      )}`;
      return `<a href='${absoluteUrl}' target="_blank"; style="color: blue;">${fileName}</a>`;
    };

    pdfContent.innerHTML = `
    <div style="display: flex; margin-bottom: 20px; color:#000042;">
        <img src='/Assets/images/Logo.png' style="width: 50px; margin-right: 10px;" />
        <h1 style="font-size: 24px; margin: 10px 0;"><b>ClientForm - ${
          data.film
        } - ${data.name_of_Artist}</b></h1>
    </div>
    <br/>
    <div style="text-align: left; margin: 0 auto; color:#000042;">
        <div class="table-container" style="margin-bottom: 40px;">
            <h2 style="font-size: 24px; margin: 10px 0;">Industrial Information</h2>
            <table style="margin: 0 auto; width: 100%; border-collapse: collapse;">
                <tbody>
                    <tr><td style="padding: 8px;">Film Name:</td><td style="padding: 8px;">${
                      data.film
                    }</td></tr>
                    <tr><td style="padding: 8px;">Artist Name:</td><td style="padding: 8px;">${
                      data.name_of_Artist
                    }</td></tr>
                    <tr><td style="padding: 8px;">E-mail:</td><td style="padding: 8px;">${
                      data.email
                    }</td></tr>
                    <tr><td style="padding: 8px;">Phone Number:</td><td style="padding: 8px;">${
                      data.phone_no
                    }</td></tr>
                    <tr><td style="padding: 8px;">Father's Name:</td><td style="padding: 8px;">${
                      data.father_Name
                    }</td></tr>
                    <tr><td style="padding: 8px;">Category of Service:</td><td style="padding: 8px;">${
                      data.category_of_Service
                    }</td></tr>
                    <tr><td style="padding: 8px;">Nature of Service:</td><td style="padding: 8px;">${
                      data.nature_of_Service
                    }</td></tr>
                </tbody>
            </table>
        </div>
        
          <div class="table-container" style="margin-bottom: 40px;">
            <h2 style="font-size: 24px; margin: 10px 0;">ID Proofs</h2>
            <table style="margin: 0 auto; width: 100%; border-collapse: collapse;">
                <tbody>
                    <tr><td style="padding: 8px;">PAN Number:</td><td style="padding: 8px;">${
                      data.paN_Number
                    }</td></tr>
                    <tr><td style="padding: 8px;">Attachment:</td><td style="padding: 8px;">${
                      data.paN_Doc_URL
                        ? createAnchor(data.paN_Doc_URL)
                        : "No Attachment"
                    }</td></tr>
                    <tr><td style="padding: 8px;">Aadhar Number:</td><td style="padding: 8px;">${
                      data.aadhar_Number
                    }</td></tr>
                    <tr><td style="padding: 8px;">Attachment:</td><td style="padding: 8px;">${
                      data.aadhar_Doc_URL
                        ? createAnchor(data.aadhar_Doc_URL)
                        : "No Attachment"
                    }</td></tr>
                    <tr><td style="padding: 8px;">GST Registered:</td><td style="padding: 8px;">${
                      data.gst
                    }</td></tr>
                    <tr><td style="padding: 8px;">Attachment:</td><td style="padding: 8px;">${
                      data.gsT_Certificate_URL
                        ? createAnchor(data.gsT_Certificate_URL)
                        : "No Attachment"
                    }</td></tr>
                </tbody>
            </table>
        </div>
         <br/>
         <br/>
        <div class="table-container" style="margin-bottom: 40px;">
            <h2 style="font-size: 24px; margin: 10px 0;">Payment Details</h2>
            <table style="margin: 0 auto; width: 100%; border-collapse: collapse;">
                <tbody>
                    <tr><td style="padding: 8px;">No. of Shooting Days:</td><td style="padding: 8px;">${
                      data.no_of_Shooting_Days
                    }</td></tr>
                    <tr><td style="padding: 8px;">No. of Months:</td><td style="padding: 8px;">${
                      data.no_of_Months
                    }</td></tr>
                    <tr><td style="padding: 8px;">Assistants Payments:</td><td style="padding: 8px;">${
                      data.assistants_Payments
                    }</td></tr>
                    <tr><td style="padding: 8px;">Payment Deal:</td><td style="padding: 8px;">${
                      data.payment_Deal
                    }</td></tr>
                    <tr><td style="padding: 8px;">Payment Trance:</td><td style="padding: 8px;">${
                      data.payment_Trance
                    }</td></tr>
                </tbody>
            </table>
        </div>

      

        <div class="table-container" style="margin-bottom: 40px;">
            <h2 style="font-size: 24px; margin: 10px 0;">Bank Details</h2>
            <table style="margin: 0 auto; width: 100%; border-collapse: collapse;">
                <tbody>
                    <tr><td style="padding: 8px;">Account Holder:</td><td style="padding: 8px;">${
                      data.account_holder_Name
                    }</td></tr>
                    <tr><td style="padding: 8px;">Bank Name:</td><td style="padding: 8px;">${
                      data.bank_Name
                    }</td></tr>
                    <tr><td style="padding: 8px;">Account Number:</td><td style="padding: 8px;">${
                      data.account_no
                    }</td></tr>
                    <tr><td style="padding: 8px;">IFSC Code:</td><td style="padding: 8px;">${
                      data.ifsC_Code
                    }</td></tr>
                    <tr><td style="padding: 8px;">Branch Name:</td><td style="padding: 8px;">${
                      data.branch
                    }</td></tr>
                </tbody>
            </table>
        </div>

        <div class="table-container">
            <h2 style="font-size: 24px; margin: 10px 0;">Other Attachments</h2>
            <table style="margin: 0 auto; width: 100%; border-collapse: collapse;">
                <tbody>
                    <tr>
                        <td>
                            <div style="display: grid; grid-template-columns: repeat(3, 1fr); gap: 10px;">
                                ${
                                  store.getvalue("attachmenturl") &&
                                  store.getvalue("attachmenturl").length > 0
                                    ? store
                                        .getvalue("attachmenturl")
                                        .map(
                                          (item) => `
                                        <div style="display: flex; align-items: center;">
                                            ${createAnchor(item)}
                                        </div>
                                    `
                                        )
                                        .join("")
                                    : "No Attachments"
                                }
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <br/>
    <div class="table-container">
    <br/>
    </div>
`;
    document.body.appendChild(pdfContent);

    html2pdf()
      .from(pdfContent)
      .set(options)
      .save()
      .then(() => {
        document.body.removeChild(pdfContent);
      });
  };

  return (
    <>
      <FormView
        actions={[
          {
            Name: "Generate PDF",
            type: "",
            onclick: generatePDF,
            visible: true,
          },
          {
            Name: "Back",
            onclick: () => {
              store.navback();
              store.reset(init);
            },
            visible: true,
          },
          {
            Name: "Create Document",
            type: "primary",
            onclick: () => {
              let data = store.getrecord();
              console.log("On Create Project - ", data);

              store.setsystemvalue("module", "document");
              // store.setvaluetomodule("document", "project_id", id);
              store.setrecord("document", {
                film_id: data.film_id,
                contact_id: data.contact_id,
                form_id: id,
              });
              // store.setvaluetomodule("document", "film_id", data.film_id);
              // store.setvaluetomodule("document", "contact_id", data.contact_id);
              // store.setvaluetomodule("document", "form_id", id);
              store.navTo(
                "/demo/documents/create?film_id=readonly&contact_id=readonly&form_id=readonly"
              );
            },
            visible: option === "view" && getlocal("properties")?.document?.add,
          },
        ]}
      >
        <div className="grid grid-cols-2 gap-6">
          <div className="col-span-2 grid grid-cols-2 gap-6">
            <div className="font-bold text-[20px] col-span-2">
              Industrial Information
            </div>
            <String name="film" label="Flim Name :" />
            <String name="name_of_Artist" label="Artist Name :" />
            <String name="email" label="E-mail :" />
            <String name="phone_no" label="Phone Number :" />
            <String name="father_Name" label="Fathers Name :" />
            <String name="category_of_Service" label="Category of Service :" />
            <String name="nature_of_Service" label="Nature of Service :" />
          </div>
          <div className="col-span-2 grid grid-cols-2 gap-6">
            <div className="font-bold text-[20px] col-span-2">
              Payment Details
            </div>
            <String name="no_of_Shooting_Days" label="No. of Shooting Days :" />
            <String name="no_of_Months" label="No. of Months :" />
            <String name="assistants_Payments" label="Assistants Payments" />
            <String name="payment_Deal" label="Payment Deal :" />
            <String name="payment_Trance" label="Payment Trance :" />
          </div>
          <div className="col-span-2 grid grid-cols-2 gap-6">
            <div className="font-bold text-[20px] col-span-2">Id Proofs</div>
            <String name="paN_Number" label="Pan Number :" />
            <Attachement
              name="paN_Doc_URL"
              label="Attachement :"
              onclick={(link) => {}}
            />
            <String name="aadhar_Number" label="Aadhar Number :" />
            <Attachement name="aadhar_Doc_URL" label="Attachement :" />
            <String name="gst" label="GST registered :" />
            <Attachement name="gsT_Certificate_URL" label="Attachement :" />
          </div>
          <div className="col-span-2 grid grid-cols-2 gap-6">
            <div className="font-bold text-[20px] col-span-2">Bank Details</div>
            <String name="account_holder_Name" label="Account Holder :" />
            <String name="bank_Name" label="Bank Name :" />
            <String name="account_no" label="Account Number :" />
            <String name="ifsC_Code" label="IFSC Code :" />
            <String name="branch" label="Branch Name :" />
          </div>
          <div className="col-span-2 grid grid-cols-2 gap-6">
            <div className="font-bold text-[20px] col-span-2">
              Other Attachements
            </div>
            <div className="grid grid-cols-3 col-span-2">
              {store.getvalue("attachmenturl")?.length > 0 ? (
                store.getvalue("attachmenturl").map((item, index) => {
                  // console.log("test 223", item);
                  return (
                    <div className="col-span-1 flex border-r pr-6 w-full  items-center">
                      <Attachement name={"attachmenturl"} fileurl={item} />
                      <button
                        className="hover:bg-tertiary p-1 h-fit w-fit rounded-full"
                        onClick={() => {
                          let arr = [];
                          store
                            .getvalue("attachmenturl")
                            .map((attachitem, attachindex) => {
                              if (attachindex !== index) {
                                arr.push(attachitem);
                              }
                            });
                          store.setvalue("attachmenturl", arr);
                        }}
                      >
                        X
                      </button>
                    </div>
                  );
                })
              ) : (
                <>No Data</>
              )}
            </div>
          </div>
        </div>
      </FormView>
    </>
  );
};

export default FormsForm;
